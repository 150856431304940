import * as React from "react";
import { HTMLAst, HTMLAstNode } from "../../../templates/HTMLAst";
import { H2 } from "../../atoms/H2/H2";
import { H3 } from "../../atoms/H3/H3";
import { H4 } from "../../atoms/H4/H4";
import { Em, Img, Li, Ol, Strong } from "./RenderedHTMLAst.styles";
import { YoutubeVideo } from "./YoutubeVideo/YoutubeVideo";
import { getYoutubeVideoId } from "./YoutubeVideo/getYoutubeVideoId";
import { convertInlineStyleToJSX } from "./convertInlineStyleToJSX";
import { P } from "../../atoms/P/P";
import { TelemetryLink } from "../../molecules/TelemetryLink/TelemetryLink";

interface IProps {
    readonly ast: HTMLAst;
}

const renderElement = (
    tagName: string,
    props: HTMLAstNode["properties"] & { readonly key: string | number },
    children: React.ReactNode[],
    _key: string | number
): React.ReactNode => {
    switch (tagName) {
        case "span":
            return (
                <span {...props} style={convertInlineStyleToJSX(props.style ?? "")}>
                    {children}
                </span>
            );
        case "img":
            return <Img {...props} style={convertInlineStyleToJSX(props.style ?? "")} />;
        case "h2":
            return <H2 {...props}>{children}</H2>;
        case "h3":
            return <H3 {...props}>{children}</H3>;
        case "h4":
            return <H4 {...props}>{children}</H4>;
        case "p":
            return <P {...props}>{children}</P>;
        case "em":
            return <Em {...props}>{children}</Em>;
        case "strong":
            return <Strong {...props}>{children}</Strong>;
        case "ol":
            return <Ol {...props}>{children}</Ol>;
        case "li":
            return <Li {...props}>{children}</Li>;
        case "a":
            const href = props.href as string;
            const videoId = getYoutubeVideoId(href);
            if (videoId) {
                return <YoutubeVideo key={_key} videoId={videoId} />;
            }
            return (
                <TelemetryLink key={_key} href={href} target="_blank">
                    {children}
                </TelemetryLink>
            );
        default:
            console.log(`Unhandled tag: ${tagName}`);
            console.log(props);
            console.log(children);
            return React.createElement(tagName, props, children);
    }
};

const renderAstNode = (node: HTMLAstNode, key: string | number): React.ReactNode => {
    switch (node.type) {
        case "element":
            const props = { ...node.properties, key };
            const children = node.children?.map((child, index) => renderAstNode(child, `${key}-${index}`)) || [];
            return renderElement(node.tagName || "div", props, children, key);
        case "text":
            if (node.value?.trim() !== "") {
                return <React.Fragment key={key}>{node.value}</React.Fragment>;
            }
            return null;
        default:
            return null;
    }
};

export const RenderedHTMLAst: React.FC<IProps> = ({ ast }) => {
    return <>{ast.children.map((child, index) => renderAstNode(child, index))}</>;
};
