import * as React from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../services/Constants/Constants";
import { TelemetryLink } from "../TelemetryLink/TelemetryLink";
import { LinkHeading, MenuItem, MenuLinkStyledOverride, SectionWrapper } from "./Footer.styles";

export const Connect: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <SectionWrapper>
            <LinkHeading>{t("footer.letsConnect")}</LinkHeading>
            <ul>
                <MenuItem>
                    <TelemetryLink href={Constants.TwitterLink} target="_blank" styledOverride={MenuLinkStyledOverride}>
                        {t("footer.twitter")}
                    </TelemetryLink>
                </MenuItem>
                <MenuItem>
                    <TelemetryLink href={Constants.Linkedin} target="_blank" styledOverride={MenuLinkStyledOverride}>
                        {t("footer.ln")}
                    </TelemetryLink>
                </MenuItem>
                <MenuItem>
                    <TelemetryLink href={Constants.Github} target="_blank" styledOverride={MenuLinkStyledOverride}>
                        {t("footer.github")}
                    </TelemetryLink>
                </MenuItem>
                <MenuItem>
                    <TelemetryLink href={Constants.Youtube} target="_blank" styledOverride={MenuLinkStyledOverride}>
                        {t("footer.youtube")}
                    </TelemetryLink>
                </MenuItem>
                <MenuItem>
                    <TelemetryLink href={Constants.Discord} target="_blank" styledOverride={MenuLinkStyledOverride}>
                        {t("footer.discord")}
                    </TelemetryLink>
                </MenuItem>
                <MenuItem>
                    <TelemetryLink href={Constants.InstagramLink} target="_blank" styledOverride={MenuLinkStyledOverride}>
                        {t("footer.instagram")}
                    </TelemetryLink>
                </MenuItem>
            </ul>
        </SectionWrapper>
    );
};
