import styled from "styled-components";
import { Color } from "../../layout/GlobalStyles/Color";

export interface IRouterLinkStyleProps {
    readonly fontSize?: string;
    readonly color?: string;
    readonly hoverColor?: string;
    readonly textDecoration?: string;
}

export const Root = styled("span")<IRouterLinkStyleProps>`
    font-size: ${({ fontSize }) => fontSize ?? "inherit"};
    cursor: pointer;
    font-weight: 400;
    color: ${({ color }) => color ?? Color.Foreground};
    text-decoration: ${({ textDecoration }) => textDecoration ?? "none"};

    :hover {
        color: ${({ hoverColor }) => hoverColor ?? Color.Primary100};
    }
`;
