import * as React from "react";
import { useTranslation } from "react-i18next";
import { Connect } from "./Connect";
import { BottomWrapper, Container, LinksWrapper, LogoWrapper, Root, Subtitle, Wrapper } from "./Footer.styles";

interface IProps {
    readonly isDarkTheme?: boolean;
}

export const Footer: React.FC<React.PropsWithChildren<IProps>> = ({ isDarkTheme = false }) => {
    const [t] = useTranslation();

    return (
        <Root isDarkTheme={isDarkTheme}>
            <Container>
                <Wrapper>
                    <div>
                        <LogoWrapper>
                            <Subtitle>{t("footer.tagLine")}</Subtitle>
                        </LogoWrapper>
                    </div>
                    <LinksWrapper>
                        <Connect />
                    </LinksWrapper>
                </Wrapper>
                <BottomWrapper>{`${t("footer.copyright")}`}</BottomWrapper>
            </Container>
        </Root>
    );
};
