import styled from "styled-components";

export const Strong = styled.strong`
    font-weight: bold;
`;

export const Em = styled.em`
    font-style: italic;
`;

export const Img = styled.img`
    border-radius: 8px;
`;

export const Ol = styled.ol`
    padding-left: 24px;
`;
export const Li = styled.li`
    list-style-type: counter;
`;
