export const getYoutubeVideoId = (urlString: string): string | null => {
    // Regex pattern to match the YouTube protocol format and capture the video ID
    const pattern = /^youtube:\/\/([a-zA-Z0-9_-]+)$/;
    const match = urlString.match(pattern);
    if (!match) {
        return null;
    }
    const [_, videoId] = match;
    return videoId;
};
