import * as React from "react";
import { ResponsiveIframe, Root } from "./YoutubeVideo.styles";

interface IProps {
    readonly videoId: string;
}

export const YoutubeVideo: React.FC<IProps> = ({ videoId }) => {
    const src = `https://www.youtube.com/embed/${videoId}`;
    return (
        <Root>
            <ResponsiveIframe width="800" height="600" src={src} allow="autoplay; encrypted-media" allowFullScreen></ResponsiveIframe>;
        </Root>
    );
};
