export const convertInlineStyleToJSX = (inlineStyle: string): Record<string, string> => {
    // Split the inline style string into individual styles
    const styles = inlineStyle.split(";");

    // Reduce the array of styles into a style object
    const jsxStyle = styles.reduce<Record<string, string>>((acc, currentStyle) => {
        if (currentStyle.trim()) {
            // Split each style into property and value
            const [property, value] = currentStyle.split(":");
            if (property && value) {
                // Convert the CSS property name from kebab-case to camelCase
                const jsxProperty = property.trim().replace(/-./g, (match) => match[1].toUpperCase());

                // Add the style property and value to the accumulator object
                acc[jsxProperty] = value.trim();
            }
        }
        return acc;
    }, {});

    return jsxStyle;
};
