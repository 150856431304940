import styled from "styled-components";

export const H3 = styled.h3`
    color: inherit;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    line-height: 1.4;
    margin-block-start: 1em;
    margin-block-end: 1em;
`;
